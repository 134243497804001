import React, { useEffect, useRef, useState } from "react"
import { Transition } from "@headlessui/react"
import ShirtDropMain from "../../videos/shirt-drop/shirt-drop-main.mp4"

type Props = {
  onSkipIntro: () => void
}

const Animation: React.FC<Props> = ({ onSkipIntro }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const videoRef = useRef<HTMLVideoElement>(null)

  const [playing, setPlaying] = useState(false)

  useEffect(() => {
    playing && videoRef.current && videoRef.current.play()
  }, [playing])

  return (
    <>
      <section
        className="relative flex aspect-[600/1066] flex-col items-center justify-center landscape:aspect-video landscape:w-full"
        ref={containerRef}
      >
        <video
          ref={videoRef}
          src={ShirtDropMain}
          playsInline
          controls={false}
          className={`max-h-[100vh] object-contain ${
            playing ? "block" : "hidden"
          }`}
          onEnded={onSkipIntro}
        />
        {!playing && (
          <button
            className="z-[1338] flex items-center justify-center whitespace-nowrap rounded-full bg-green py-2 px-4 text-center font-gravity-wide text-[12px] uppercase leading-normal text-black md:text-[14px] lg:bottom-[2vh] lg:left-auto lg:right-4 lg:translate-x-0"
            onClick={() => setPlaying(true)}
          >
            Initiate Mint
          </button>
        )}
      </section>

      <Transition
        show={playing}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <button
          className="absolute bottom-4 left-1/2 z-[1338] flex -translate-x-1/2 items-center justify-center whitespace-nowrap rounded-full bg-green py-2 px-4 text-center font-gravity-wide text-[12px] uppercase leading-normal text-black md:text-[14px] lg:bottom-[2vh] lg:left-auto lg:right-4 lg:translate-x-0"
          onClick={onSkipIntro}
        >
          Skip Animation
        </button>
      </Transition>
    </>
  )
}

export default Animation
