import React, { useState } from "react"
import useFetchEventData from "../../hooks/useFetchEventData"
import { API_EVENTS } from "../../events"

type Props = {
  nextStep: () => void
}

const SelectSize: React.FC<Props> = () => {
  const [selectedSizeUrl, setSelectedSize] = useState<string | null>(null)
  const { eventData } = useFetchEventData(API_EVENTS.SHIRT_DROP)

  const openSelection = () => {
    selectedSizeUrl && window.open(selectedSizeUrl);
  }

  const sizes = eventData?.data?.sizes;

  return (
    <div className="w-full h-full flex flex-col gap-4 justify-center items-center font-gravity-wide uppercase">
      <span>Select Shirt size</span>
      <div className="w-full flex flex-row gap-4 justify-center items-center font-gravity-wide uppercase">
        {eventData?.data?.sizes && <select className="rounded-full" onChange={e => {console.log(e.target.value); setSelectedSize(e.target.value)}}>
          <option disabled selected>Select size</option>
          {Object.keys(sizes).map(size => <option key={size} value={sizes[size as keyof typeof sizes]}>{size}</option>)}
        </select>}
        <button 
          className={`${selectedSizeUrl ? '' : 'opacity-80'} text-md w-[160px] rounded-full bg-green py-2 px-4 text-center font-gravity-wide uppercase leading-normal text-black lg:w-[220px]`} 
          onClick={openSelection} 
          disabled={!selectedSizeUrl}
        >Mint</button>
      </div>
    </div>
  )
}

export default SelectSize
