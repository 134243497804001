import React, { useState } from "react"
import DefaultView from "./defaultView"
import SelectSize from "./selectSize"

export type SecretData = {
    location: string,
    text: string
}

const pdpSteps = {
  DEFAULT: 0,
  SIZE_SELECT: 1,
  CONFIRM: 2,
}

const Pdp: React.FC = () => {
  const [pdpStep, setPdpStep] = useState(pdpSteps.DEFAULT);

  return (
    <section className="!font-suisse-intl w-full relative flex flex-col h-[90%]">
      <h1 className="mt-[66px] mb-4 flex w-full flex-col items-center justify-center text-center font-gravity-wide text-[16px] font-black uppercase leading-[1.2] md:text-[40px] lg:text-[2.65vw]">
        <span>OREMOB SHIRT DROP 01</span>
      </h1>
      <div className="relative flex-1">
        {pdpStep === pdpSteps.DEFAULT && (
          <DefaultView
            nextStep={() => setPdpStep(pdpSteps.SIZE_SELECT)}
          />
        )}
        {pdpStep === pdpSteps.SIZE_SELECT && (
          <SelectSize 
            nextStep={() => setPdpStep(pdpSteps.SIZE_SELECT)}
          />
        )}
      </div>
      {pdpStep === pdpSteps.SIZE_SELECT && (
        <div className="flex w-full justify-center pb-4 text-[12px] lg:text-[14px]">
          <button
            onClick={() => {
              setPdpStep(s => s - 1)
            }}
            className="text-md w-[160px] rounded-full bg-green py-2 px-4 text-center font-gravity-wide uppercase leading-normal text-black lg:w-[220px]"
          >
            Back
          </button>
        </div>
      )}
    </section>
  )
}

export default Pdp
